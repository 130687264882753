@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,600;0,700;1,300;1,400&family=Rubik:ital,wght@0,300;0,400;1,300;1,400&family=Poppins:wght@700&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #ffbf63;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #ffbf63;
}

html {
    scroll-behavior: smooth;
}
body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat';
}

header {
    -webkit-animation: heartBeat;
            animation: heartBeat;
    display: flex;
    align-items: center;
    min-height: 94px;
    top: 0;
    z-index: 10;
    transition: background-color 500ms ease-out;
}
header img {
    width: 150px;
    height: 40px;
    /* padding-left: 97px; */
}
span.com {
    position: relative;
}
span.com:after {
    background-color: #ffbf63;
    content: '';
    height: 30%;
    position: absolute;
    left: 5px;
    margin-left: -1rem;
    top: 60%;
    width: calc(100% + 2.57rem);
    z-index: -1;
}

#hero-wrap {
    padding-top: 100px;
    /* padding-left: 97px; */
    overflow: visible;
    text-align: left;
    /* font-family: 'Rubik', sans-serif; */
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    color: #212125;
    height: 400px;
    width: 100%;
    display: inline-block;
}
.hero-shape {
    width: 1px;
    height: 330px;
}
#services {
    padding-top: 70px;
    min-height: 500px;
    display: inline-block;
    width: 100%;
    background-color: #f7fafd;
}

#technologies {
    min-height: 50px;
    display: inline-block;
    width: 100%;
    background-color: #f7fafd;
}

#team {
    min-height: 50px;
    display: inline-block;
    width: 100%;
}

#contact {
    display: inline-block;
    width: 100%;
    padding-bottom: 50px;
    background-color: #f7fafd;
}

h1 {
    font-size: 24px;
    color: #3f3d56;
    font-family: 'Montserrat';
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
}

h3 {
    font-family: 'Montserrat';
    text-align: center;
    color: #3f3d56;
}

h3.t-text {
    margin-bottom: 0;
    margin-top: 10px;
    font-weight: 400;
    font-size: 20px;
    font-family: 'Montserrat';
    line-height: 22px;
}

.muted {
    color: #ffbf63;
}

li {
    text-align: left;
    list-style-type: none;
}

h1.text-left {
    font-size: 24px;
    color: #3f3d56;
    font-family: 'Montserrat';
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    margin-top: 150px;
}

h1.co.text-left {
    font-size: 24px;
    color: white;
    font-family: 'Montserrat';
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    margin-top: 150px;
}

p.co.text-left {
    color: white;
}

.self-stretch {
    align-self: stretch !important;
}

footer {
    position: absolute;
    font-family: 'Montserrat';
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    color: white;
    background-color: #1f2d41;
    text-align: center;
}

.contact-section,
.contact-section h4,
.contact-section a {
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 600;
    color: #1f2d41;
    margin-top: 50px;
}

.contact-section a:hover {
    color: #ffbf63;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
}

#title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.sline {
    width: 60px;
    height: 2px;
}

section.banner {
    background-image: url('/images/header.svg');
    background-position: 73% 99% 73%, left top right;
    position: relative;
    justify-content: center;
    background-size: cover, auto;
    background-repeat: no-repeat;
    min-height: 200px;
    height: calc(100vh + 128px);
    overflow: hidden;
    max-height: 550px;
}

.nav-link {
    font-family: 'Rubik';
    font-size: 18px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
}

.navbar-light .navbar-toggler-icon {
    background-image: url('/images/menu.svg') !important;
    outline: none;
    text-decoration: none;
}

.navbar-toggle {
    border: none;
    outline: none;
}

.navbar-nav {
    position: relative;
    overflow: hidden;
    zoom: 1;
}

.nav-link a {
    position: relative;
    top: 5px;
    display: block;
    margin: -5px 0 0;
    padding: 10px 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
}

.is-active a {
    color: #ffbf63 !important;
}

.magic-line {
    position: absolute;
    bottom: 0;
    left: 5px;
    z-index: 3;
    margin: 0;
    border: 0;
    width: 0;
    height: 2px;
    padding: 0;
    overflow: hidden;
    text-indent: -9999em;
    background: #ffbf63;
    transition-property: left, width;
    transition-duration: 0.5s;

    pointer-events: none;
}

.nav-link:hover {
    z-index: 2;
}

.n1:hover ~ .magic-line,
.n1.is-active ~ .magic-line {
    left: 10px;
    width: 73px;
}

.n2:hover ~ .magic-line,
.n2.is-active ~ .magic-line {
    left: 115px;
    width: 75px;
}

.n3:hover ~ .magic-line,
.n3.is-active ~ .magic-line {
    left: 235px;
    width: 98px;
}

.n4:hover ~ .magic-line,
.n4.is-active ~ .magic-line {
    left: 385px;
    width: 33px;
}

.n5:hover ~ .magic-line,
.n5.is-active ~ .magic-line {
    left: 445px;
    width: 60px;
}

.n6:hover ~ .magic-line {
    left: 545px;
    width: 38px;
}

.nav-link:hover,
.is-active {
    color: #ffbf63 !important;
}

.smaller {
    padding: 0 20px !important;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
        0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

.smaller img {
    width: 120px !important;
    height: 30px !important;
}

.selected {
    color: #3f3d56 !important;
}

.service-img {
    width: 120px;
    height: 120px !important;
}

.port-img {
    width: 570px;
    height: 380px !important;
}

.portfolio {
    width: 400px !important;
    padding: 0 !important;
}

.port-down {
    width: 100% !important;
    background-color: #3f3d56;
}

.contact-section li a {
    text-decoration: none;
    padding-right: 25px;
}
.p-t {
    color: white;
    text-align: left;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Montserrat';
    margin-left: -45px;
    margin-right: -15px;
    margin-top: 150px;
}

.getStartedbtn {
    border-radius: 50rem !important;
    font-weight: 300 !important;
    text-transform: uppercase !important;
    background-color: #ffbf63 !important;
    color: #3f3d56 !important;
    width: 12rem;
    height: 3.3rem;
    border-color: transparent !important;
}

.getStartedbtn a {
    text-decoration: none !important;
    color: #3f3d56 !important;
}

.getStartedbtn > a:hover {
    color: #e6ac59 !important;
}

.getStartedbtn:hover {
    background-color: #f7fafd !important;
    color: #e6ac59 !important;
    border-color: #e6ac59 !important;
    border-width: 2px !important;
    box-shadow: 0 10px 10px -2px rgba(31, 146, 244, 0.12);
    transition: all 0.5s ease-in-out;
}

@media (max-width: 768px) {
    .getStartedbtn {
        display: none !important;
    }
    .p-t {
        margin-top: 0px;
    }
    section.banner {
        background-size: cover auto;
        background-repeat: no-repeat;
        background-image: url('https://res.cloudinary.com/noahalorwu/image/upload/v1595957618/hero-pattern.svg');
        color: #3f3d56;
    }
    #hero-wrap {
        padding-top: 90px;
        padding-left: 30px;
        padding-right: 5px;
        font-family: 'Rubik', sans-serif;
        font-size: 40px !important;
        font-weight: normal !important;
        text-transform: none !important;
        color: white;
    }

    header {
        background-color: white;
        min-height: 75px;
    }

    .navbar-nav {
        margin-top: 8px;
        border-top: 0.7px;
        text-align: left;
    }

    span.com:after {
        background-color: transparent;
    }

    .navbar-nav .nav-link {
        color: #3f3d56 !important;
    }

    .magic-line {
        position: unset;
        bottom: 0;
        left: 0;
        z-index: 0;
        margin: 0;
        border: 0;
        width: 0;
        height: 0;
        padding: 0;
        overflow: hidden;
        text-indent: 0;
        background: #ffbf63;
        transition-property: left, width;
        transition-duration: 0.5s;

        pointer-events: none;
    }

    h1.co.text-left,
    h1.text-left {
        margin: 0;
    }

    .lds-ripple {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    .lds-ripple div {
        position: absolute;
        border: 4px solid;
        opacity: 1;
        border-radius: 50%;
        -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
                animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    .lds-ripple div:nth-child(2) {
        -webkit-animation-delay: 0.5s;
                animation-delay: 0.5s;
    }
    @-webkit-keyframes lds-ripple {
        0% {
            top: 36px;
            left: 36px;
            width: 0;
            height: 0;
            opacity: 1;
        }
        100% {
            top: 0px;
            left: 0px;
            width: 72px;
            height: 72px;
            opacity: 0;
        }
    }
    @keyframes lds-ripple {
        0% {
            top: 36px;
            left: 36px;
            width: 0;
            height: 0;
            opacity: 1;
        }
        100% {
            top: 0px;
            left: 0px;
            width: 72px;
            height: 72px;
            opacity: 0;
        }
    }

    .arrow-down {
        position: absolute;
        width: 28px;
        height: 8px;
        opacity: 0;
        -webkit-transform: scale3d(0.5, 0.5, 0.5);
                transform: scale3d(0.5, 0.5, 0.5);
        -webkit-animation: move 3s ease-out infinite;
                animation: move 3s ease-out infinite;
    }

    .arrow-down:first-child {
        -webkit-animation: move 3s ease-out 1s infinite;
                animation: move 3s ease-out 1s infinite;
    }

    .arrow-down:nth-child(2) {
        -webkit-animation: move 3s ease-out 2s infinite;
                animation: move 3s ease-out 2s infinite;
    }

    .arrow-down:before,
    .arrow-down:after {
        content: ' ';
        position: absolute;
        top: 0;
        height: 100%;
        width: 51%;
        background: #fff;
    }

    .arrow-down:before {
        left: 0;
        -webkit-transform: skew(0deg, 30deg);
                transform: skew(0deg, 30deg);
    }

    .arrow-down:after {
        right: 0;
        width: 50%;
        -webkit-transform: skew(0deg, -30deg);
                transform: skew(0deg, -30deg);
    }

    @-webkit-keyframes move {
        25% {
            opacity: 1;
        }
        33% {
            opacity: 1;
            -webkit-transform: translateY(30px);
                    transform: translateY(30px);
        }
        67% {
            opacity: 1;
            -webkit-transform: translateY(40px);
                    transform: translateY(40px);
        }
        100% {
            opacity: 0;
            -webkit-transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
                    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
        }
    }

    @keyframes move {
        25% {
            opacity: 1;
        }
        33% {
            opacity: 1;
            -webkit-transform: translateY(30px);
                    transform: translateY(30px);
        }
        67% {
            opacity: 1;
            -webkit-transform: translateY(40px);
                    transform: translateY(40px);
        }
        100% {
            opacity: 0;
            -webkit-transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
                    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
        }
    }

    h3.mb {
        font-size: small;
    }
}

button.navbar-toggler {
    outline: none !important;
    border: none;
}

